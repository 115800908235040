<template>
  <v-card :title="`${$t('admin.assets.lookupValues.replaceValue', { lookupValue: lookupValue.description })}`">
    <v-card-text>
      <v-alert class="mb-3" type="warning">{{ $t("admin.assets.lookupValues.replaceWarningText") }}</v-alert>

      <v-autocomplete
        v-model="replacementValue"
        :items="lookupValues"
        :label="$t('admin.assets.lookupValues.replacementValue')"
        :loading="lookupValuesPending"
        class="mb-2"
        clearable
        item-title="description"
        item-value="_id"
      >
        <template #item="{ item, props: itemProps }">
          <v-list-item v-bind="itemProps" :title="item.raw.description">
            <template #subtitle>
              <v-chip v-if="item.raw.organization" density="comfortable" color="info">{{ item.raw.organization!.description }}</v-chip>
              <v-chip v-else density="comfortable" color="success">{{ $t("assets.structure.template.generic") }}</v-chip>

              <v-chip v-if="item.raw.parent" density="comfortable" color="primary">{{ item.raw.parent!.description }}</v-chip>
            </template>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn color="primary" @click="emit('cancel')">{{ $t("cancel") }}</v-btn>
      <v-btn color="error" :loading="loading" :disabled="!replacementValue" @click="() => onReplace()">{{ $t("replace") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: "submit" | "cancel"): void
}>()

const props = defineProps<{ lookupValue: LookupValue }>()
const { lookupValue } = toRefs(props)

const $i18n = useI18n()

const mainStore = useMainStore()
const lookupValueStore = useLookupValueStore()

const loading = ref(false)
const replacementValue = ref<string>()

const { data: lookupValues, pending: lookupValuesPending } = useLazyAsyncData(
  "lookup-values-" + lookupValue.value.lookupType,
  async () => {
    const organizationQuery = lookupValue.value.organization
      ? {
          $or: [{ organization: { $eq: lookupValue.value.organization } }, { organization: { $exists: false } }],
        }
      : { organization: { $exists: false } }

    const parentQuery = lookupValue.value.parent
      ? {
          $or: [{ parent: { $eq: lookupValue.value.parent } }, { parent: { $exists: false } }],
        }
      : { parent: { $exists: false } }

    const populateQuery = [
      {
        path: "organization",
        model: "Organization",
        select: ["description"],
      },
      {
        path: "lookupType",
        model: "LookupType",
        select: ["description"],
      },
      {
        path: "parent",
        model: "LookupValue",
        select: ["description"],
      },
    ]

    const response = await lookupValueStore.getLookupValuesByPage({
      pagination: false,
      select: ["description", "parent", "organization"],
      query: JSON.stringify({
        ...organizationQuery,
        ...parentQuery,
        lookupType: lookupValue.value.lookupType,
      }),
      populate: JSON.stringify(populateQuery),
    })

    const docs = response.docs as unknown as Array<
      LookupValue & { organization?: { _id: string; description: string } } & { lookupType?: { _id: string; description: string } } & {
        parent?: { _id: string; description: string }
      }
    >

    // Remove this lookup value from the list
    docs.splice(
      docs.findIndex((doc) => doc._id === lookupValue.value._id),
      1,
    )

    // sort on parent.description descending (can be null) then descending on organization.description (can be null) then on description
    docs.sort((a, b) => {
      if (a.parent?.description === b.parent?.description) {
        if (a.organization?.description === b.organization?.description) {
          return a.description.localeCompare(b.description)
        }

        return (a.organization?.description || "").localeCompare(b.organization?.description || "")
      }

      return (b.parent?.description || "").localeCompare(a.parent?.description || "")
    })

    return docs
  },
  {
    default: () =>
      [] as Array<
        LookupValue & { organization?: { _id: string; description: string } } & { lookupType?: { _id: string; description: string } } & {
          parent?: { _id: string; description: string }
        }
      >,
  },
)

const onReplace = async () => {
  if (!replacementValue.value) {
    return
  }

  await useExplicitSave(mainStore.notify, $i18n, async () => {
    loading.value = true

    try {
      await lookupValueStore.replaceLookupValue(lookupValue.value._id, replacementValue.value!)
      emit("submit")
    } finally {
      loading.value = false
    }
  })
}
</script>
