<template>
  <v-card :title="$t('admin.assets.lookupValues.delete')">
    <v-card-text>{{ $t("admin.assets.lookupValues.deleteText") }}</v-card-text>

    <v-card-text v-if="linkedAssets.length">
      <v-alert type="error">{{ $t("admin.assets.lookupValues.deleteErrorLinkedProperties", linkedAssets.length) }}</v-alert>

      <v-card variant="outlined" class="mt-3">
        <v-virtual-scroll max-height="400" item-height="40" :items="linkedAssets">
          <template #default="{ item: asset }">
            <v-list-item :key="asset._id">
              <template #title>{{ asset.key }} | {{ asset.description }}</template>
              <template #subtitle>{{ asset.organization.description }}</template>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-card>
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn color="primary" @click="emit('cancel')">{{ $t("cancel") }}</v-btn>
      <v-btn color="error" :loading="loading" :disabled="!!linkedAssets.length" @click="() => onDelete()">{{ $t("delete") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: "submit" | "cancel"): void
}>()

const props = defineProps<{ lookupValue: LookupValue }>()
const { lookupValue } = toRefs(props)

const $i18n = useI18n()

const mainStore = useMainStore()
const lookupValueStore = useLookupValueStore()

const { data: linkedAssets } = useAsyncData(
  `linked-properties:${lookupValue.value._id}`,
  async () => {
    return await lookupValueStore.getAssetsByLookupValue(lookupValue.value._id)
  },
  { default: () => [] as Array<LookupAssetWithOrganization> },
)

const loading = ref(false)

const onDelete = async () => {
  await useExplicitSave(
    mainStore.notify,
    $i18n,
    async () => {
      loading.value = true

      try {
        await lookupValueStore.deleteLookupValue(lookupValue.value._id)
        emit("submit")
      } finally {
        loading.value = false
      }
    },
    "remove",
  )
}
</script>
