<template>
  <v-card style="max-width: 1000px" :loading="loading">
    <v-card-item class="pr-2">
      <template #title>
        <span>{{
          $t("admin.assets.lookupValues.objectUsage", { assetCount: assets?.length ?? 0, lookupValue: lookupValue.description })
        }}</span>
        <v-btn variant="text" :loading="loading" icon="refresh" @click="emit('refresh')" />
      </template>
    </v-card-item>

    <v-card-text>
      <v-table v-if="assets?.length" :height="300" density="comfortable" class="bg-transparent overflow-auto" fixed-header hover>
        <colgroup>
          <col class="w-0" />
          <col class="w-0" />
        </colgroup>

        <thead>
          <tr>
            <th class="text-no-wrap">{{ $t("organization") }}</th>
            <th class="text-no-wrap">{{ $t("asset") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="linkedAsset in assets" :key="linkedAsset._id">
            <td class="text-no-wrap">{{ linkedAsset.organization.description }}</td>
            <td class="text-no-wrap">{{ linkedAsset.key }} | {{ linkedAsset.description }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: "refresh"): void
}>()

const props = defineProps<{
  lookupValue: LookupValue
  assets?: Array<LookupAssetWithOrganization>
  loading: boolean
}>()

const { lookupValue, loading, assets } = toRefs(props)
</script>
